import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {User} from '../../../models/user'
import { faCertificate} from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";
import {HttpService} from "../../../services/http.service";

@Component({
  selector: 'app-chips-participants',
  templateUrl: './chips-participants.component.html',
  styleUrls: ['./chips-participants.component.scss']
})
export class ChipsParticipantsComponent implements OnInit {
  faCertificate= faCertificate;
  @Input() user;
  @Input() invitation;
  @Input() rolId;
  @Input() active;
  @Output() part = new EventEmitter<any>();
  @Output() deleteInvitationPart = new EventEmitter<any>();
  @Output() rolSuplente = new EventEmitter<any>()

  constructor(public router: Router, public httpService: HttpService) {
  }

  ngOnInit() {}

  deletePart(user: User) {
    this.part.emit(user)
  }

  deleteInv(user) {
    this.deleteInvitationPart.emit(user)
  }

  assignSuplent(user: User) {
    // this.isSuplente = !this.isSuplente;
    // this.rolSuplente.emit(this.isSuplente)
    this.rolSuplente.emit(user);
  }

}
