import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatAutocomplete, MatChipInputEvent, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {HttpService} from '../../../services/http.service';
import {Group} from '../../../models/group';
import {User} from '../../../models/user';
import {Meeting} from '../../../models/meeting';
import {ModalConstants} from '../modal-constants'
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-modal-meeting',
  templateUrl: './modal-meeting.component.html',
  styleUrls: ['./modal-meeting.component.scss']
})
export class ModalMeetingComponent implements OnInit {
  public title: string;
  formGroup: FormGroup = new FormGroup({
    title: new FormControl(null, [Validators.pattern('^(?=.*[a-zA-Z]).{2,}$'), Validators.required]),
    group: new FormControl(null,[Validators.required]),
    userInv: new FormControl(null, [Validators.pattern('^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$')]),
    userInv2: new FormControl(false, []),
    date: new FormControl(null, [Validators.required]),
    // Location: new FormControl(null, [Validators.required])
  });
  selectedOptions: User[] = [];
  listInvitations=[] = [];
  sendList=[];
  groupFilteredOptions: Group[] = [];
  userByGroup: User[] = [];
  idGroup:number=0;
  updatedIdGroup: number
  origins:any;
  today = new Date();
  checked = true;
  selectedGroup;
  @ViewChild('userInput', {static: false}) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('userAutoComplete', {static: false}) matAutocomplete: MatAutocomplete;


  constructor(public dialogRef: MatDialogRef<ModalMeetingComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private httpService: HttpService,private toastr: ToastrService) {
                this.groupFilteredOptions = data.groups;
                this.idGroup = data.groupId;
                this.formGroup.get('date').setValue(new Date());

  }

  ngOnInit() {
    this.getOrigin();
    if(this.idGroup){
      this.getUserByGroup(this.idGroup);
      this.getGroupById(this.idGroup);
    }else{
      this.groupFilteredOptions = this.data.groups;
    }

  }
  onNgModelChange(user, $event) {
    if ($event === true && !this.selectedOptions.includes(user)) {
      user.selected = true;
      this.selectedOptions.push(user);
    }
    if ($event === false) {
      user.selected = false;
      this.selectedOptions = this.selectedOptions.filter(s => s !== user);
    }
  }
  getGroupById(id: number) {
    this.httpService.get(ModalConstants.GET_GROUP_BY_ID(id)).subscribe(res => {
      this.formGroup.get('group').setValue(res.groups.name);
    });
  }
  getOrigin(){
    this.httpService.get(ModalConstants.GET_ORIGIN_URI()).subscribe(res=>{
      this.origins = res.origin;
    })
  }
  onSave() {
    const groupSelect: Group = this.groupFilteredOptions.filter((i) => i.name === this.formGroup.get('group').value)[0];
    // this.formGroup.get('userInv').value
    this.httpService.get(ModalConstants.GET_USER_BY_ID_URI(this.httpService.userId)).subscribe(res => {
      if (res) {
        for (let k in this.selectedOptions) {
          if (this.selectedOptions[k].id !== res.user.id) {
            this.sendList.push(this.selectedOptions[k]);
          }
        }
        for (let k in this.listInvitations) {
            this.sendList.push(this.listInvitations[k]);
        }

        this.sendList.push(res.user);
        const meeting: Meeting = {
          title: this.formGroup.get('title').value,
          date: this.formGroup.get('date').value,
          // oriId: this.formGroup.get('Location').value,
          grpId: groupSelect.id,
          grpName: groupSelect.name,
          usrId: this.httpService.userId,
          usrName: `${res.user.firstName} ${res.user.lastName}`,
          status: 2,
          participants: this.sendList
        };
        this.dialogRef.close({
          meeting
        });
      }
    });

  }


  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  getUserByGroup(idGroup: number) {
    this.selectedGroup = idGroup;
    this.httpService.get(ModalConstants.GET_USERS_BY_GROUPS_URI(idGroup)).subscribe(res => {
      this.updatedIdGroup = idGroup;
      this.userByGroup = [];
      this.selectedOptions = [];
      let newArr = [];
      for (let user of res.users) {

        user.selected = true;
        newArr.push(user);

        // La logica del negocio dice que por defecto todos deben estar selecionados,
        // si un miembro no esta presente entonces, se desmarca
        this.selectedOptions.push(user);
      }
      this.userByGroup = newArr
    });
  }

  deleteInvList(user: User){
    this.listInvitations = this.listInvitations.filter( (p) => p.id != user.id );
  }

    getUserByOrgName(controlName: string, errorName: string){
      const inv=this.formGroup.get('userInv').value;
      let registeredEmail: boolean

      if(!inv){
        this.toastr.info('Debe agregar un correo electrónico')
        // this.snackBar.open('Debe agregar un correo electrónico', null, {duration: 4000});
      }else if(!this.updatedIdGroup){
        this.toastr.info('Debe primero seleccionar un grupo')
        // this.snackBar.open('Debe primero seleccionar un grupo', null, {duration: 4000});
      }else{

        this.httpService.get(ModalConstants.GET_USERS_BY_EMAIL(inv)).subscribe(res=>{
          if(res.user){
            let user = res.user
            this.httpService.get(ModalConstants.GET_USER_ROL_GROUP(res.user.id, this.updatedIdGroup)).subscribe(res=>{

              if(res.rol){
                this.toastr.warning('Este invitado ya es miembro del grupo')
                // this.snackBar.open('Este invitado ya es miembro del grupo', null, {duration: 4000});
              }else{
                registeredEmail = this.registeredEmail(this.listInvitations,user.email)
                if(!this.hasError(controlName, errorName) && registeredEmail === false){
                  this.listInvitations.unshift(user)
                  this.formGroup.get('userInv').setValue('');
                }else{
                  this.toastr.warning('Ya invitó a este participante')
                  // this.snackBar.open('Ya invito a este participante', null, {duration: 4000});
                }
              }
            })
          }else{
 /*           registeredEmail = this.registeredEmail(this.listInvitations,inv)
                    if(!this.hasError(controlName, errorName) && registeredEmail === false){
                      this.listInvitations.unshift(inv)
                      this.formGroup.get('userInv').setValue('');
                    }else{
                      this.snackBar.open('Ya invito a este participante', null, {duration: 4000});
                    }*/
            this.toastr.warning('El usuario no está registrado en ABX')
            // this.snackBar.open('El usuario no está registrado en ABX', null, {duration: 4000});
          }
        })

      }
  }

  //valida si el invitado se ha agregado en el listado
  registeredEmail(list_Invitations : any[], email: string){
    let registeredEmail: boolean = false

    for(var k in list_Invitations) {
      if(list_Invitations[k].email){
        if(list_Invitations[k].email === email) return registeredEmail = true
      }else{
        if(this.listInvitations[k] === email) return registeredEmail = true
      }
    }
    return registeredEmail
  }

}

