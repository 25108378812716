import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatAutocomplete, MatChipInputEvent, MatDialogRef} from '@angular/material';
import {MAT_DATE_FORMATS} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {Form, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {Group} from '../../../models/group';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {User} from '../../../models/user';
import {HttpService} from '../../../services/http.service';
import {NotesConstants} from '../../../../pages/home/dashboard/notes/notes-constants';
import {Assignment} from '../../../models/assignment';
import {Comment} from '../../../models/comment';
import {Meeting} from '../../../models/meeting';
import {ModalConstants} from '../modal-constants';
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {History} from "../../../models/history";
import {HttpAudithorService} from "../../../services/http.audithor.service";
import {ToastrService} from "ngx-toastr";

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-modal-assignment',
  templateUrl: './modal-assignment.component.html',
  styleUrls: ['./modal-assignment.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class ModalAssignmentComponent implements OnInit {
  public title: string;
  mood;
  formGroup: FormGroup = new FormGroup({
    title: new FormControl({value: '', disabled: true}, [Validators.required]),
    group: new FormControl({value: '', disabled: true}, [Validators.required]),
    meeting: new FormControl({value: '', disabled: true}, [Validators.required]),
    userToAssign: new FormControl({value: '', disabled: true}, []),
    deliveryDate: new FormControl({value: '', disabled: true}, [Validators.required]),
    notification: new FormControl({value: '', disabled: false}, [Validators.required]),
    description: new FormControl({value: '', disabled: true}, [Validators.required]),
    tag: new FormControl({value: '', disabled: true}, []),
    // commet: new FormControl(null, [])
  });
  formComment: FormGroup = new FormGroup({
    comment: new FormControl('', [Validators.required])
  });
  tags: string[] = [];
  groups: Group[] = [];
  groupId: number;
  usersToAssign: User[] = [];
  selectedMeet: Meeting;
  userList: User[] = [];
  selectedUser: User;
  meeting: Meeting;
  meetings: Meeting[];
  Comments: Comment[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  groupFilteredOptions: Observable<Group[]>;
  usersFilteredOptions: Observable<User[]>;
  @ViewChild('userInput', {static: false}) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('userAutoComplete', {static: false}) matAutocomplete: MatAutocomplete;

  pattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

  constructor(public dialogRef: MatDialogRef<ModalAssignmentComponent>, @Inject(MAT_DIALOG_DATA) public data, public httpService: HttpService, private toastr: ToastrService, private httpAudithorService: HttpAudithorService) {
    // this.groups = data.groups;
    this.meeting = data.meeting;
    this.meetings = data.Meetings;
    this.groupId = data.groupId;
    this.mood = data.mood;
    // if( this.meeting ) this.formGroup.get('meeting').setValue(this.meeting.title)
  /*
    if (this.groupId) {
      this.formGroup.get('group').setValue(this.groups.filter(g => g.id == this.groupId)[0])
      this.httpService.get(NotesConstants.GET_MEETINGS_BY_GROUP_URI(this.groupId)).subscribe(res => {
        this.meetings = res.meeting
        if (this.httpService.selectAssig) {
          this.formGroup.get('meeting').setValue(this.meetings.filter(g => g.id == this.httpService.selectAssig.meeId)[0])
        }
      })

      /!*  this.httpService.get(NotesConstants.GET_USERS_BY_GROUP_URI(this.groupId)).subscribe(res => {
          this.usersToAssign = res.users;
          this.usersFilteredOptions = this.formGroup.get('userToAssign').valueChanges
            .pipe(
              startWith(''),
              map(value => this.userFilter(value)
              ));
        });*!/
    }
    this.groupFilteredOptions = this.formGroup.get('group').valueChanges
      .pipe(
        startWith(''),
        map(value => this.groupFilter(value)
        ));*/

  }

  groupDisplayWith(option) {
    return option !== null ? option.name : '';
  }

  ngOnInit() {
    // this.getGroupById(this.groupId);
    this.formGroup.get('group').valueChanges.subscribe(group => {
      if (!this.httpService.selectAssig) {
        this.httpService.get(NotesConstants.GET_MEETINGS_BY_GROUP_URI(group.id)).subscribe(res => {
          this.meetings = res.meeting
        })
      }
      this.getCommentByAssignment(this.httpService.userId, this.httpService.selectAssig.id)
    })

    this.formGroup.get('meeting').valueChanges.subscribe(meet => {
      this.selectedMeet = meet
      this.httpService.get(NotesConstants.GET_PARTICIPANTS_BY_MEETING(Number(this.meeting))).subscribe(res => {
        this.usersToAssign = res.users;
        this.usersFilteredOptions = this.formGroup.get('userToAssign').valueChanges
          .pipe(
            startWith(''),
            map(value => this.userFilter(value)
            ));
      })
    })


    if (this.httpService.selectAssig != undefined) {
      this.httpService.get(NotesConstants.GET_MEETINGS_BY_GROUP_URI(this.groupId)).subscribe(res => {
        this.meetings = res.meeting
        this.formGroup.get('meeting').setValue(this.meetings.filter(g => g.id == this.httpService.selectAssig.meeId)[0])
      })

      this.formGroup.get('title').setValue(this.httpService.selectAssig.title);
      this.formGroup.get('description').setValue(this.httpService.selectAssig.content),
        this.formGroup.get('deliveryDate').setValue(this.httpService.selectAssig.finalDate),
        this.formGroup.get('group').setValue(this.httpService.selectAssig.group),
        this.formGroup.get('notification').setValue(this.httpService.selectAssig.notification)
      this.selectedUser = this.httpService.selectAssig.user;
    }

  }

  getGroupById(id: number) {
    this.httpService.get(ModalConstants.GET_GROUP_BY_ID(id)).subscribe(res => {
      this.formGroup.get('group').setValue(res.groups.name);
    })
  }

  displayWith(option) {
    return option !== null ? '' : '';
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  removeUser(): void {
    this.selectedUser = null;
    this.formGroup.get('userToAssign').reset();
  }

  selectUser(): void {
    this.selectedUser = this.usersToAssign.find(app => app.id === this.formGroup.get('userToAssign').value.id);
    this.userInput.nativeElement.value = '';
  }

/*  private groupFilter(value: any): Group[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.groups.filter(group => group.name.toLowerCase().includes(filterValue));
    } else {
      return this.groups;
    }
  }*/

  private userFilter(value: any): User[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.usersToAssign.filter(user => user.firstName.toLowerCase().includes(filterValue)
        || user.lastName.toLowerCase().includes(filterValue));
    } else {
      return this.usersToAssign;
    }
  }

  onSave() {
    // console.log(this.selectedMeet)
    const assignment: Assignment = {
      title: this.formGroup.get('title').value,
      content: this.formGroup.get('description').value,
      initialDate: new Date(),
      finalDate: this.formGroup.get('deliveryDate').value,
      grpId: this.formGroup.get('group').value.id,
      notification: this.formGroup.get('notification').value === 0 || this.formGroup.get('notification').value === null ? 5 : this.formGroup.get('notification').value,
      // meeId: this.selectedMeet.id
      meeId: Number(this.meeting)
    };

    if (this.formGroup.get('userToAssign').value !== null) {
      assignment.usrId = this.formGroup.get('userToAssign').value.id;
    }

    this.dialogRef.close({
      assignment
    });

  }

  addComment() {
    // console.log(this.httpService.selectAssig)
    if (this.httpService.selectAssig) {
      const comment: Comment = {
        description: this.formComment.get('comment').value,
        updatedAt: new Date(),
        asmId: this.httpService.selectAssig.id,
        usrId: this.httpService.userId,
      };
      this.httpService.post(ModalConstants.CREATE_COMMENT_ASSIGNMENT_URI(this.httpService.userId), comment).subscribe(res => {
        const history: History = {
          his_user: this.httpService.userId,
          his_products: 'ABX',
          his_field: '',
          his_action: 'agregado una actualizacion',
          his_date: new Date(),
          his_entity: 'assignment',
          his_identity: this.httpService.selectAssig.id,
        }
        this.httpAudithorService.post(NotesConstants.GET_HISTORY(), history).subscribe(res2 => {})
        this.getCommentByAssignment(this.httpService.userId, this.httpService.selectAssig.id)
      }, error => {
        // console.log(error)
      })
    }
  }

  getCommentByAssignment(idUser, idAssig) {
    this.httpService.get(ModalConstants.GET_COMMENT_ASSIGNMENT_URI(idUser, idAssig)).subscribe(res => {
      this.Comments = res;
      // console.log(res)

      function parseDate(dateStr, format) {
        const regex = format.toLocaleLowerCase()
          .replace(/\bd+\b/, '(?<day>\\d+)')
          .replace(/\bm+\b/, '(?<month>\\d+)')
          .replace(/\by+\b/, '(?<year>\\d+)')

        const parts = new RegExp(regex).exec(dateStr);
        let {year, month, day} = parts.groups;
        return parts.length === 4 ? new Date(Number(day), Number(month) - 1, Number(year)) : undefined;
      }

      let options = {year: '2-digit', month: '2-digit', day: '2-digit'};
      this.Comments.map(async comment => {
        comment[`createdAt`] = parseDate(comment[`createdAt`], 'DD-MM-YY')
        comment[`createdAt`] = comment[`createdAt`].toLocaleDateString("es-ES", options)
      })
      this.getUserListBycComment(this.Comments);
    })
  }

  getUserListBycComment(comments: Comment[]) {
    comments.forEach((c) => {
      this.getUserById(c.usrId);
    })
  }

  getUserById(id) {
    this.httpService.get(ModalConstants.GET_USER_BY_ID_URI(id)).subscribe(res => {
      this.userList.push(res['user']);
    })
  }

  preClose() {
    this.httpService.selectAssig.status = 3; //Se cambia el atributo status a Precerrar
    this.httpService.put(ModalConstants.GET_STATUS_BY_ASSIGNMENT_URI(this.httpService.userId), this.httpService.selectAssig).subscribe(res => {
      this.toastr.success(`Se ha Preccerado la asignación ${res['assignments'].title}`)
     /* this.snackBar.open(`Se ha Preccerado la asignación ${res['assignments'].title}`, null, {
        duration: 4000
      });*/
    })
  }

}
