/*export const environment = {
  production: true,
  baseUrl: 'http://localhost:3000/api/v1/',
  baseMicroservice: 'http://localhost:3002/api/v1/',
  baseMicroserviceAudithor: 'http://localhost:3001/api/v1/'
};*/

export const environment = {
  production: true,
  baseUrl: '//coreabx.intelix.biz/api/v1/',
  baseMicroservice: '//notifiesabx.intelix.biz/api/v1/',
  baseMicroserviceAudithor: '//audithorabx.intelix.biz/api/v1/'
};
/*export const environment = {
  production: true,
  baseUrl: 'http://localhost:3000/api/v1/',
  baseMicroservice: 'http://localhost:3002/api/v1/',
  baseMicroserviceAudithor: 'http://localhost:3001/api/v1/'
};*/
