import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {HttpService} from '../../../services/http.service';
import {User} from '../../../models/user';
import {MeetingInformacionConstants} from "../../../../pages/home/meeting/meeting-information/meeting-constants";


@Component({
  selector: 'app-modal-meeting-participants',
  templateUrl: './modal-meeting-participants.component.html',
  styleUrls: ['./modal-meeting-participants.component.scss']
})
export class ModalMeetingParticipantsComponent implements OnInit {
  public title: string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  idGroup: number = 0;
  idUser: number = 0
  participant: User;
  participants: User[] = new Array()
  users: User[]
  allParticipants = [] = [];
  meeting;

  constructor(public dialogRef: MatDialogRef<ModalMeetingParticipantsComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private httpService: HttpService) {
    this.meeting = data.meeting;
  }

  ngOnInit() {
      this.getParticipants(this.meeting)
  }

  getParticipants(idMee: number) {
    this.httpService.get(MeetingInformacionConstants.GET_ALL_PARTICIAPNT_BY_MEETING(idMee)).subscribe(res => {
      let participants: any[] = res['participants']
      for (var k in participants) {
        if (participants[k].userId !== null) {
          this.idUser = participants[k].userId
          this.httpService.get(MeetingInformacionConstants.GET_USER_BY_ID(this.idUser)).subscribe(res => {
            if (res.user.firstName != ' ' || res.user.lastName != ' ') {
              this.allParticipants.unshift(res.user)
            } else {
              this.allParticipants.unshift(res.user.email)
            }
          })
        } else {
          this.allParticipants.unshift(participants[k].userEmail)
        }
      }
    })
  }

}
