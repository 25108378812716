import {Component} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ABX';
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ){
    this.matIconRegistry.addSvgIcon(
      "binocular",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../../assets/icons/binocular.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add-group",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../../assets/icons/add-user.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "affiliate",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../../assets/icons/candidates.svg")
    );
  }
}
