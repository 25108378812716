import { Component, OnInit,Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material';
import {HttpService} from '../../../services/http.service';
import {Group} from '../../../models/group';
import {MatSnackBar} from '@angular/material';
import {ModalConstants} from "../modal-constants";
import {Meeting} from "../../../models/meeting";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-modal-delete-meeting',
  templateUrl: './modal-delete-meeting.component.html',
  styleUrls: ['./modal-delete-meeting.component.scss']
})
export class ModalDeleteMeetingComponent implements OnInit {
  public title: string;
  meeting:Meeting;
  constructor(public dialogRef: MatDialogRef<ModalDeleteMeetingComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private httpService: HttpService, private toastr: ToastrService) {
    this.meeting = data.meeting;
  }

  ngOnInit() { }

  //Elimina meeting seleccionado
  deleteMeeting(){
    this.httpService.delete(ModalConstants.DELETE_MEETING_URI(this.meeting.id)).subscribe(res=>{
      // console.log(res)
      this.toastr.success('La reunión se ha eliminado con éxito')
     /* this.snackBar.open('La reunión se ha eliminado con éxito', null, {
        duration: 10000
      })*/
    })
  }
}
